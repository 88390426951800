import { config } from '../config.js';
import { createSchema } from "./index.js"

export const schemaModel = createSchema({
    schemaName: 'model',
    displayName: 'Model Manager',
    collectionName: 'Model',
    icon: 'ico_rv.svg',
    itemPath: 'meta.name',
    searchKeys: ['meta.oem', 'meta.modelSeries'],
    previewUrl: config.serverDomain + '/preview/specsheet',
    // exportSwitchIndex: 2,
    
    defaultSort: {
        updateTime: -1
    },
    
    defaults: {
        "features": {
            "247Direct": true 
        },
        "model": {
            "specs": {
                "engine": {
                    "fuelType": "Diesel"
                }
            }
        }
    },
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 7
                },
                {
                    name: 'Last Published',
                    path: 'publishTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            type: 'category',
            name: 'General',
            hideName: true,
            fields: [
                {
                    name: 'Coach Image',
                    type: 'img',
                    path: 'images.hero',
                    lineAfter: true
                },
                // {
                //     name: 'Chassis Code',
                //     type: 'info',
                //     path: 'meta.chassisCode'
                // },
                {
                    name: 'OEM',
                    type: 'text',
                    width: 50,
                    path: 'meta.oem',
                    linkedInfo: {
                        schema: 'oem',
                        path: 'name'
                    },
                    flex: 2,
                    sequence: 3
                },
                {
                    name: 'Model Name',
                    type: 'text',
                    width: 50,
                    path: 'meta.name',
                    suggestionFilters: [
                        'meta.oem'
                    ],
                    lineAfter: true,
                    flex: 2,
                    sequence: 4
                },
                {
                    name: 'Model Year',
                    type: 'number',
                    width: 33.3333333,
                    path: 'meta.modelYear',
                    sequence: 2
                },
                {
                    name: 'Model Series',
                    type: 'text',
                    width: 33.3333333,
                    path: 'meta.modelSeries',
                    linkedInfo: {
                        schema: 'modelSeries',
                        path: 'name'
                    }
                },
                {
                    name: 'Chassis Type',
                    type: 'text',
                    width: 33.3333333,
                    path: 'meta.chassisType',
                    sequence: 1,
                    lineAfter: true
                },
                {
                    name: 'Model Webpage',
                    type: 'url',
                    path: 'links.oemUrl',
                    suggestions: 0,
                    lineAfter: true,
                    flex: 3
                },
                {
                    name: 'Specsheet PDF URL',
                    note: 'This field will be set automatically on new models by clicking Update PDF',
                    type: 'url',
                    path: 'links.specPdfUrl',
                    suggestions: 0,
                    lineAfter: true,
                    flex: 3
                },
                {
                    name: 'Long Specsheet PDF URL',
                    note: 'This field will be set automatically on new models by clicking Update PDF',
                    type: 'url',
                    path: 'links.specLongPdfUrl',
                    suggestions: 0,
                    lineAfter: true,
                    flex: 3
                },
                {
                    name: 'Brochure PDF URL',
                    note: 'The file will need uploaded manually to the CDN by a developer',
                    type: 'url',
                    path: 'links.brochurePdfUrl',
                    suggestions: 1,
                    lineAfter: true,
                    flex: 3
                },
                {
                    name: 'Public',
                    description: 'Should this model be visible to the public?',
                    type: 'boolean',
                    path: 'meta.public',
                    sequence: 6
                },
                {
                    name: 'Force Show on Kiosk',
                    description: 'Should this model be forced to show on the public kiosk?',
                    type: 'boolean',
                    path: 'meta.publicKiosk',
                    sequence: 7
                },
                // {
                //     name: 'Hershey 2021',
                //     description: 'Will this model be featured in Hershey 2021?',
                //     type: 'boolean',
                //     path: 'featuredHershey21',
                //     // sequence: 6
                // },
                {
                    name: 'Trade Show',
                    description: 'Will this model be featured in the current trade show?',
                    type: 'boolean',
                    path: 'featuredTradeShow',
                    // sequence: 6
                },
                {
                    name: 'Has Tag Axle',
                    description: 'Does this model have a Tag Axle?',
                    type: 'boolean',
                    path: 'specs.axles.hasTag'
                }
            ]
        },
        {
            name: 'Features',
            type: 'category',
            fields: [
                {
                    name: '24/7 Direct',
                    img: 'https://cdn.fccchq.com/features/24-7-direct.png',
                    type: 'boolean',
                    path: 'features.247Direct',
                    width: 10
                },
                {
                    name: 'V-Ride',
                    img: 'https://cdn.fccchq.com/features/vride_logo.png',
                    type: 'boolean',
                    allowOne: [
                        'features.vSeries',
                    ],
                    path: 'features.vRide',
                    width: 10
                },
                {
                    name: 'V-Series',
                    img: 'https://cdn.fccchq.com/features/v-series-logo.png',
                    type: 'boolean',
                    allowOne: [
                        'features.vRide',
                    ],
                    path: 'features.vSeries',
                    width: 10
                },
                {
                    name: '60 Degree',
                    img: 'https://cdn.fccchq.com/features/60degrees_logo.png',
                    type: 'boolean',
                    path: 'features.60Degree',
                    width: 10
                },
                {
                    name: 'UltraSteer',
                    img: 'https://cdn.fccchq.com/features/ultrasteer_logo.png',
                    type: 'boolean',
                    path: 'features.ultraSteer',
                    width: 10
                },
                {
                    name: 'OptiView',
                    img: 'https://cdn.fccchq.com/features/OptiView_black.png',
                    type: 'boolean',
                    path: 'features.optiView',
                    width: 10
                },
                {
                    name: 'RoadWatch',
                    img: 'https://cdn.fccchq.com/features/RoadWatch.png',
                    type: 'boolean',
                    path: 'features.roadWatch',
                    width: 10,
                    
                    afterSave: function(value, oldValue, ctrl) {
                        console.log('afterSave RoadWatch', value, 'old:', oldValue);
                        
                        if (value) {
                            const path = 'specs.controls.safetyFeatures';
                            ctrl.Item.save(path + '.collisionMit', true, 'boolean');
                            ctrl.Item.save(path + '.acc', true, 'boolean');
                            ctrl.Item.save(path + '.escRss', true, 'boolean');
                            ctrl.Item.save(path + '.atc', true, 'boolean');
                        }
                    }
                },
                {
                    name: 'DriveTech',
                    img: 'https://cdn.fccchq.com/features/DriveTech.png',
                    type: 'boolean',
                    path: 'features.driveTech',
                    width: 10
                },
                {
                    name: 'BrakeSync',
                    img: 'https://cdn.fccchq.com/features/BrakeSync.png',
                    type: 'boolean',
                    path: 'features.brakeSync',
                    width: 10
                }
            ]
        },
        // {
        //     name: 'Features',
        //     type: 'category',
        //     fields: [
        //         {
        //             name: '24/7 Direct',
        //             img: 'https://cdn.fccchq.com/features/24-7-direct.png',
        //             type: 'booleanList',
        //             path: 'meta.features',
        //             value: '24/7 Direct',
        //             width: 20
        //         },
        //         {
        //             name: 'V-Ride',
        //             img: 'https://cdn.fccchq.com/features/vride_logo.png',
        //             type: 'booleanList',
        //             path: 'meta.features',
        //             value: 'V-Ride',
        //             width: 20
        //         },
        //         {
        //             name: '60 Degree',
        //             img: 'https://cdn.fccchq.com/features/60degrees_logo.png',
        //             type: 'booleanList',
        //             path: 'meta.features',
        //             value: '60 Degree',
        //             width: 20
        //         },
        //         {
        //             name: 'Ultra Steer',
        //             img: 'https://cdn.fccchq.com/features/ultrasteer_logo.png',
        //             type: 'booleanList',
        //             path: 'meta.features',
        //             value: 'Ultra Steer',
        //             width: 20
        //         },
        //         {
        //             name: 'OptiView',
        //             img: 'https://cdn.fccchq.com/features/optiview_logo.png',
        //             type: 'booleanList',
        //             path: 'meta.features',
        //             value: 'OptiView',
        //             width: 20
        //         }
        //     ]
        // },
        {
            name: 'Floorplans',
            type: 'category',
            fields: [
                {
                    name: 'Floorplans',
                    hideName: true,
                    type: 'list',
                    path: 'floorplans',
                    fields: [
                        {
                            name: 'Floorplan Name',
                            width: 30,
                            path: 'name',
                            sequence: 5,
                            suggestions: 0,
                            flex: 2
                        },
                        {
                            name: 'Coach Length',
                            width: 30,
                            path: 'coachLength',
                            suggestions: 0,
                            type: 'number',
                            suffix: {
                                text: 'ft',
                                width: 25
                            }
                        },
                        {
                            name: 'Floorplan Image (PNG image no more than 1200 pixels wide)',
                            path: 'images.floorplan',
                            type: 'img',
                            width: 40
                        }
                    ]
                }
            ]
        },
        {
            name: 'Engine',
            type: 'category',
            fields: [
                {
                    name: 'Manufacturer',
                    columnName: 'Engine Manufacturer',
                    type: 'text',
                    width: 50,
                    path: 'specs.engine.manufacturer'
                },
                {
                    name: 'Model',
                    columnName: 'Engine Model',
                    type: 'text',
                    width: 50,
                    path: 'specs.engine.model',
                    lineAfter: true,
                    suggestionFilters: [
                        'specs.engine.manufacturer'
                    ]
                },
                {
                    name: 'Fuel Type',
                    type: 'select',
                    width: 50,
                    path: 'specs.engine.fuelType',
                    lineAfter: true,
                    default: 'Diesel',
                    noEmpty: true,
                    options: [
                        "Diesel",
                        "Gasoline",
                        "Electric"
                    ]
                },
                {
                    name: 'Horsepower',
                    type: 'category',
                    fields: [
                        {
                            name: 'HP',
                            hideName: true,
                            type: 'number',
                            width: 40,
                            path: 'specs.engine.horsePower.hp',
                            suggestionFilters: [
                                'specs.engine.model'
                            ],
                            suffix: {
                                text: 'hp',
                                width: 40
                            }
                        },
                        {
                            name: 'rpm',
                            columnName: 'RPM of peak HP',
                            hideName: true,
                            type: 'number',
                            width: 60,
                            path: 'specs.engine.horsePower.rpm',
                            suggestionFilters: [
                                'specs.engine.horsePower.hp',
                                'specs.engine.model'
                            ],
                            prefix: {
                                text: '@',
                                width: 20
                            },
                            suffix: {
                                text: 'rpm',
                                width: 40
                            }
                        }
                    ]
                },
                {
                    name: 'Torque',
                    type: 'category',
                    fields: [
                        {
                            name: 'lbf-ft',
                            columnName: 'Torque',
                            hideName: true,
                            type: 'number',
                            width: 40,
                            path: 'specs.engine.torque.lbfFt',
                            suggestionFilters: [
                                'specs.engine.model',
                                'specs.engine.horsePower.hp'
                            ],
                            inferThreshold: 0.9,
                            suffix: {
                                text: 'lb-ft',
                                width: 40
                            }
                        },
                        {
                            name: 'rpm',
                            columnName: 'RPM of peak Torque',
                            hideName: true,
                            type: 'number',
                            width: 60,
                            path: 'specs.engine.torque.rpm',
                            suggestionFilters: [
                                'specs.engine.model',
                                'specs.engine.horsePower.hp',
                                'specs.engine.torque.lbfFt'
                            ],
                            prefix: {
                                text: '@',
                                width: 20
                            },
                            suffix: {
                                text: 'rpm',
                                width: 40
                            }
                        }
                    ]
                },
                {
                    name: 'Brake',
                    type: 'category',
                    fields: [
                        {
                            name: 'Brake',
                            hideName: true,
                            type: 'select',
                            path: 'specs.engine.brake',
                            width: 60,
                            options: [
                                {
                                    value: '',
                                    label: 'None'
                                },
                                'VGT (Variable Geometry Turbocharger) Brake (single stage)',
                                'Engine Compression Brake (three stage)'
                            ]
                        }
                    ]
                },
                
            ]
        },
        {
            name: 'Transmission',
            type: 'category',
            fields: [
                {
                    name: 'Manufacturer',
                    columnName: 'Transmission Manufacturer',
                    type: 'text',
                    width: 40,
                    path: 'specs.transmission.manufacturer'
                },
                {
                    name: 'Model',
                    columnName: 'Transmission Model',
                    type: 'text',
                    width: 60,
                    path: 'specs.transmission.model',
                    suggestionFilters: [
                        'specs.transmission.manufacturer'
                    ]
                }
            ]
        },
        {
            name: 'Weight Ratings',
            type: 'category',
            fields: [
                {
                    name: 'GAWR',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Front',
                            columnName: 'GVWR Front',
                            type: 'number',
                            width: 33.333,
                            path: 'specs.weightRating.gvwr.front',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        },
                        {
                            name: 'Rear',
                            columnName: 'GVWR Rear',
                            type: 'number',
                            width: 33.333,
                            path: 'specs.weightRating.gvwr.rear',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        },
                        {
                            name: 'Tag',
                            type: 'number',
                            width: 33.333,
                            path: 'specs.weightRating.gvwr.tag',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        }
                    ]
                },
                {
                    name: 'GVWR',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'GVWR',
                            columnName: 'GVWR',
                            type: 'number',
                            width: 33.333,
                            path: 'specs.weightRating.gvwr.total',
                            hideName: true,
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        }
                    ]
                },
                {
                    name: 'GCWR',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'GCWR',
                            hideName: true,
                            type: 'number',
                            width: 33.333,
                            path: 'specs.weightRating.gcwr.total',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            },
                            formula: 'gcwr'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Axles',
            type: 'category',
            fields: [
                {
                    name: 'Front',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Front Axle Manufacturer',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.front.manufacturer'
                        },
                        {
                            name: 'Model',
                            columnName: 'Front Axle Model',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.front.model',
                            suggestionFilters: [
                                'specs.axles.front.manufacturer'
                            ]
                        },
                        {
                            name: 'Type',
                            columnName: 'Front Axle Type',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.front.type'
                        },
                        {
                            name: 'Capacity',
                            columnName: 'Front Axle Capacity',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.front.capacity',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        }
                    ]
                },
                {
                    name: 'Rear',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Rear Axle Manufacturer',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.rear.manufacturer'
                        },
                        {
                            name: 'Model',
                            columnName: 'Rear Axle Model',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.rear.model',
                            suggestionFilters: [
                                'specs.axles.rear.manufacturer'
                            ]
                        },
                        {
                            name: 'Type',
                            columnName: 'Rear Axle Type',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.rear.type'
                        },
                        {
                            name: 'Capacity',
                            columnName: 'Rear Axle Capacity',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.rear.capacity',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        },
                        {
                            name: 'Axle Ratio',
                            columnName: 'Rear Axle Ratio',
                            type: 'number',
                            width: 50,
                            path: 'specs.axles.rear.ratio'
                        },
                    ]
                },
                {
                    name: 'Tag',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Tag Axle Manufacturer',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.tag.manufacturer'
                        },
                        {
                            name: 'Model',
                            columnName: 'Tag Axle Model',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.tag.model',
                            suggestionFilters: [
                                'specs.axles.tag.manufacturer'
                            ]
                        },
                        {
                            name: 'Type',
                            columnName: 'Tag Axle Type',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.tag.type'
                        },
                        {
                            name: 'Capacity',
                            columnName: 'Tag Axle Capacity',
                            type: 'text',
                            width: 50,
                            path: 'specs.axles.tag.capacity',
                            suffix: {
                                text: 'lbs',
                                width: 40
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'Brakes',
            type: 'category',
            fields: [
                {
                    name: 'Front',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Diameter',
                            columnName: 'Front Brakes Diameter',
                            type: 'number',
                            width: 34,
                            path: 'specs.brakes.front.dimensions.diameter',
                            suffix: {
                                text: '" X',
                                width: 27
                            }
                        },
                        {
                            name: 'Width',
                            type: 'number',
                            columnName: 'Front Brakes Width',
                            width: 34,
                            path: 'specs.brakes.front.dimensions.width',
                            suffix: {
                                text: '"',
                                width: 27
                            }
                        },
                        {
                            name: 'Type',
                            columnName: 'Front Brakes Type',
                            type: 'text',
                            width: 34,
                            path: 'specs.brakes.front.type',
                            suffix: {
                                text: '',
                                width: 27
                            }
                        },
                        {
                            name: 'Manufacturer',
                            columnName: 'Front Brakes Manufacturer',
                            type: 'text',
                            width: 34,
                            path: 'specs.brakes.front.manufacturer',
                            suffix: {
                                text: '',
                                width: 27
                            }
                        }
                    ]
                },
                {
                    name: 'Rear',
                    type: 'category',
                    fields: [
                        {
                            name: 'Diameter',
                            columnName: 'Rear Brakes Diameter',
                            type: 'number',
                            width: 34,
                            path: 'specs.brakes.rear.dimensions.diameter',
                            suffix: {
                                text: '" X',
                                width: 27
                            }
                        },
                        {
                            name: 'Width',
                            columnName: 'Rear Brakes Width',
                            type: 'number',
                            width: 34,
                            path: 'specs.brakes.rear.dimensions.width',
                            suffix: {
                                text: '"',
                                width: 27
                            }
                        },
                        {
                            name: 'Type',
                            columnName: 'Rear Brakes Type',
                            type: 'text',
                            width: 34,
                            path: 'specs.brakes.rear.type',
                            suffix: {
                                text: '',
                                width: 27
                            }
                        },
                        {
                            name: 'Manufacturer',
                            columnName: 'Rear Brakes Manufacturer',
                            type: 'text',
                            width: 34,
                            path: 'specs.brakes.rear.manufacturer',
                            suffix: {
                                text: '',
                                width: 27
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'Cooling System',
            type: 'category',
            fields: [
                {
                    name: 'Radiator Area',
                    type: 'number',
                    width: 30,
                    path: 'specs.coolingSystem.size',
                    suffix: {
                        text: 'sq. in.',
                        width: 50
                    }
                },
                {
                    name: 'Mount Position',
                    columnName: 'Radiator Mount Position',
                    type: 'text',
                    width: 30,
                    path: 'specs.coolingSystem.radiatorMount'
                }
            ]
        },
        {
            name: 'Electrical System',
            type: 'category',
            fields: [
                {
                    name: 'Alternator',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Alternator Manufacturer',
                            type: 'text',
                            width: 33.333333,
                            path: 'specs.alternator.manufacturer',
                        },
                        {
                            name: 'Amperage',
                            columnName: 'Alternator Amperage',
                            type: 'number',
                            width: 33.333333,
                            path: 'specs.alternator.amps',
                            suffix: {
                                text: 'amps',
                                width: 40
                            }
                        }
                    ]
                },
                {
                    name: 'Starter',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Starter Manufacturer',
                            type: 'text',
                            width: 33.333333,
                            path: 'specs.starter.manufacturer',
                        },
                        {
                            name: 'Model',
                            columnName: 'Starter Model',
                            type: 'text',
                            width: 33.333333,
                            path: 'specs.starter.model',
                            suggestionFilters: [
                                'specs.starter.manufacturer'
                            ]
                        }
                    ]
                },
                {
                    name: 'Battery',
                    type: 'category',
                    lineAfter: true,
                    fields: [
                        {
                            name: 'Quantity',
                            columnName: 'Battery Quantity',
                            type: 'number',
                            width: 33.333333,
                            path: 'specs.battery.quantity',
                        },
                        {
                            name: 'CCA',
                            columnName: 'Battery CCA',
                            type: 'number',
                            width: 33.333333,
                            path: 'specs.battery.cca',
                        }
                    ]
                }
            ]
        },
        {
            name: 'Frame',
            type: 'category',
            fields: [
                {
                    name: 'Construction',
                    type: 'text',
                    path: 'specs.frame.construction',
                    lineAfter: true,
                    suggestionFilters: [
                        'meta.modelSeries'
                    ]
                },
                {
                    name: 'Wheel Base',
                    //hideName: true,
                    type: 'number',
                    width: 33.333,
                    path: 'dimensions.wheelBase',
                    suffix: {
                        text: '"',
                        width: 40
                    },
                    lineAfter: true
                },
                {
                    name: 'Rail Dimensions',
                    type: 'template',
                    path: 'specs.frame.railDimensions',
                    paths: [
                        'specs.frame.rails.height',
                        'specs.frame.rails.width',
                        'specs.frame.rails.thickness'
                    ],
                    template: '|specs.frame.rails.[].height|" x |specs.frame.rails.[].width|" x |specs.frame.rails.[].thickness|"',
                    hide: true,
                    flex: 2
                },
                {
                    name: 'Rails',
                    type: 'list',
                    path: 'specs.frame.rails',
                    fields: [
                        {
                            name: 'Rail Type',
                            columnName: 'Rail Types',
                            type: 'text',
                            width: 20,
                            path: 'type',
                            suggestionFilters: [
                                'meta.modelSeries'
                            ]
                        },
                        {
                            name: 'Height',
                            columnName: 'Rail Heights',
                            type: 'mixedNumber',
                            width: 20,
                            path: 'height',
                            suggestionFilters: [
                                'meta.modelSeries',
                                'specs.frame.rails.type'
                            ],
                            suffix: {
                                text: '" X',
                                width: 30
                            }
                        },
                        {
                            name: 'Width',
                            columnName: 'Rail Widths',
                            type: 'mixedNumber',
                            width: 20,
                            path: 'width',
                            suggestionFilters: [
                                'meta.modelSeries',
                                'specs.frame.rails.type'
                            ],
                            suffix: {
                                text: '" X',
                                width: 30
                            }
                        },
                        {
                            name: 'Thickness',
                            columnName: 'Rail Thickness',
                            type: 'mixedNumber',
                            width: 20,
                            path: 'thickness',
                            suggestionFilters: [
                                'meta.modelSeries',
                                'specs.frame.rails.type'
                            ],
                            suffix: {
                                text: '"',
                                width: 30
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'Fuel Tank',
            type: 'category',
            fields: [
                {
                    name: 'Capacity',
                    columnName: 'Fuel Tank Capacity',
                    type: 'number',
                    width: 30,
                    path: 'specs.fuelTank.capacity',
                    suffix: {
                        text: 'gal.',
                        width: 40
                    }
                },
                {
                    name: 'Fill Side',
                    columnName: 'Fuel Fill Side',
                    type: 'text',
                    path: 'specs.fuelTank.side',
                    width: 30
                }
            ]
        },
        {
            name: 'DEF Tank',
            type: 'category',
            fields: [
                {
                    name: 'Capacity',
                    columnName: 'DEF Tank Capacity',
                    type: 'number',
                    width: 30,
                    path: 'specs.defTank.capacity',
                    suffix: {
                        text: 'gal.',
                        width: 40
                    }
                }
            ]
        },
        {
            name: 'Shocks',
            type: 'category',
            fields: [
                {
                    name: 'Manufacturer',
                    columnName: 'Shocks Manufacturer',
                    type: 'text',
                    width: 30,
                    path: 'specs.shocks.front.manufacturer'
                }
            ]
        },
        {
            name: 'Suspension',
            type: 'category',
            fields: [
                {
                    name: 'Front Wheel Cut',
                    type: 'number',
                    lineAfter: true,
                    width: 25,
                    path: 'specs.suspension.front.wheelCut',
                    suffix: {
                        text: 'degrees',
                        width: 20
                    }
                },
                {
                    name: 'Front',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Front Suspension Manufacturer',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.front.manufacturer'
                        },
                        {
                            name: 'Model',
                            columnName: 'Front Suspension Model',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.front.model',
                            suggestionFilters: [
                                'specs.suspension.front.manufacturer'
                            ]
                        },
                        {
                            name: 'Capacity',
                            columnName: 'Front Suspension Capacity',
                            type: 'number',
                            width: 25,
                            path: 'specs.suspension.front.capacity',
                            suffix: {
                                text: 'lbs',
                                width: 20
                            }
                        },
                    ]
                },
                {
                    name: 'Rear',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Rear Suspension Manufacturer',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.rear.manufacturer'
                        },
                        {
                            name: 'Model',
                            columnName: 'Rear Suspension Model',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.rear.model',
                            suggestionFilters: [
                                'specs.suspension.rear.manufacturer'
                            ]
                        },
                        {
                            name: 'Capacity',
                            columnName: 'Rear Suspension Capacity',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.rear.capacity',
                            suffix: {
                                text: 'lbs',
                                width: 20
                            }
                        },
                    ]
                },
                {
                    name: 'Tag',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Tag Suspension Manufacturer',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.tag.manufacturer'
                        },
                        {
                            name: 'Model',
                            columnName: 'Tag Suspension Model',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.tag.model',
                            suggestionFilters: [
                                'specs.suspension.tag.manufacturer'
                            ]
                        },
                        {
                            name: 'Capacity',
                            columnName: 'Tag Suspension Capacity',
                            type: 'text',
                            width: 25,
                            path: 'specs.suspension.tag.capacity',
                            suffix: {
                                text: 'lbs',
                                width: 20
                            }
                        },
                    ]
                }
            ]
        },
        {
            name: 'Wheels',
            type: 'category',
            fields: [
                {
                    name: 'Material',
                    columnName: 'Wheels Material',
                    type: 'text',
                    width: 33.3333,
                    path: 'specs.wheels.material'
                },
                {
                    name: 'Diameter',
                    columnName: 'Wheels Diameter',
                    type: 'number',
                    width: 33.3333,
                    path: 'specs.wheels.diameter',
                    suffix: {
                        text: '" X',
                        width: 30
                    },
                    suggestionFilters: [
                        'specs.wheels.width'
                    ]
                },
                {
                    name: 'Width',
                    columnName: 'Wheels Width',
                    type: 'text',
                    width: 33.3333,
                    path: 'specs.wheels.width',
                    suffix: {
                        text: '"',
                        width: 20
                    },
                    suggestionFilters: [
                        'specs.wheels.diameter'
                    ]
                }
            ]
        },
        {
            name: 'Tires',
            type: 'category',
            fields: [
                {
                    name: 'Front',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Front Tires Manufacturer',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.front.manufacturer',
                            suggestionFilters: [
                                'specs.tires.front.model'
                            ]
                        },
                        {
                            name: 'Model',
                            columnName: 'Front Tires Model',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.front.model',
                            suggestionFilters: [
                                'specs.tires.front.manufacturer'
                            ]
                        },
                        {
                            name: 'Spec',
                            columnName: 'Front Tires Spec',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.front.dimensions.spec',
                            suggestionFilters: [
                                'specs.tires.front.model',
                                'specs.wheels.diameter'
                            ]
                        },
                        {
                            name: 'Diameter',
                            columnName: 'Front Tires Diameter',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.front.dimensions.diameter',
                            suggestionFilters: [
                                'specs.tires.front.model',
                                'specs.wheels.diameter'
                            ]
                        },
                        {
                            name: 'Ply',
                            columnName: 'Front Tires Ply',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.front.dimensions.ply',
                            suggestionFilters: [
                                'specs.tires.front.model'
                            ]
                        }
                    ]
                },
                {
                    name: 'Rear',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Rear Tires Manufacturer',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.rear.manufacturer',
                            suggestionFilters: [
                                'specs.tires.rear.model'
                            ]
                        },
                        {
                            name: 'Model',
                            columnName: 'Rear Tires Model',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.rear.model',
                            suggestionFilters: [
                                'specs.tires.rear.manufacturer'
                            ]
                        },
                        {
                            name: 'Spec',
                            columnName: 'Rear Tires Spec',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.rear.dimensions.spec',
                            suggestionFilters: [
                                'specs.tires.rear.model',
                                'specs.wheels.diameter'
                            ]
                        },
                        {
                            name: 'Diameter',
                            columnName: 'Rear Tires Diameter',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.rear.dimensions.diameter',
                            suggestionFilters: [
                                'specs.tires.rear.model',
                                'specs.wheels.diameter'
                            ]
                        },
                        {
                            name: 'Ply',
                            columnName: 'Rear Tires Ply',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.rear.dimensions.ply',
                            suggestionFilters: [
                                'specs.tires.rear.model'
                            ]
                        }
                    ]
                },
                {
                    name: 'Tag',
                    type: 'category',
                    fields: [
                        {
                            name: 'Manufacturer',
                            columnName: 'Tag Tires Manufacturer',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.tag.manufacturer',
                            suggestionFilters: [
                                'specs.tires.tag.model'
                            ]
                        },
                        {
                            name: 'Model',
                            columnName: 'Tag Tires Model',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.tag.model',
                            suggestionFilters: [
                                'specs.tires.tag.manufacturer'
                            ]
                        },
                        {
                            name: 'Spec',
                            columnName: 'Tag Tires Spec',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.tag.dimensions.spec',
                            suggestionFilters: [
                                'specs.tires.tag.model',
                                'specs.wheels.diameter'
                            ]
                        },
                        {
                            name: 'Diameter',
                            columnName: 'Tag Tires Diameter',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.tag.dimensions.diameter',
                            suggestionFilters: [
                                'specs.tires.tag.model',
                                'specs.wheels.diameter'
                            ]
                        },
                        {
                            name: 'Ply',
                            columnName: 'Tag Tires Ply',
                            type: 'text',
                            width: 20,
                            path: 'specs.tires.tag.dimensions.ply',
                            suggestionFilters: [
                                'specs.tires.tag.model'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'Controls',
            type: 'category',
            fields: [
                {
                    name: 'Instruments',
                    type: 'text',
                    width: 80,
                    path: 'specs.controls.instruments'
                },
                {
                    name: 'Steering Column',
                    type: 'text',
                    width: 80,
                    path: 'specs.controls.steeringColumn',
                    //formula: 'steeringColumn'
                },
                {
                    name: 'Steering Wheel',
                    type: 'text',
                    width: 80,
                    path: 'specs.controls.steeringWheel',
                    //formula: 'steeringWheel'
                    lineAfter: true
                },
                
                {
                    name: 'Safety',
                    type: 'note',
                    html: '<p>Selecting the Roadwatch 5-Star feature will auto-select the first 4 safety features below that are included in the Roadwatch package. If the coach also comes equipped with Mobileye, you can add that feature to the spec sheet by selecting it below. If the model does not come equipped with Roadwatch, please individually select each safety feature that does come equipped on the model.</p>',
                    width: 80
                },
                {
                    name: 'Collision Mitigation (forward warning and active braking)',
                    description: 'Collision Mitigation (forward warning and active braking)',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.collisionMit',
                    // width: 15
                },
                {
                    name: 'Adaptive Cruise Control (ACC)',
                    description: 'Adaptive Cruise Control (ACC)',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.acc',
                    // width: 15
                },
                {
                    name: 'Electronic Stability Control (ESC) with Roll Stability Support (RSS)',
                    description: 'Electronic Stability Control (ESC) with Roll Stability Support (RSS)',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.escRss',
                    // width: 15
                },
                {
                    name: 'Automatic Traction Control (ATC)',
                    description: 'Automatic Traction Control (ATC)',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.atc',
                    // width: 15
                },
                {
                    name: 'Mobileye Sensor Detection',
                    description: 'Mobileye Sensor Detection',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.me',
                    // width: 15
                },
                {
                    name: 'Overhead Object Detection',
                    description: 'Overhead Object Detection',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.ood',
                    // width: 15
                },
                {
                    name: '360º Camera System',
                    description: '360º Camera System',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.360degrees',
                    // width: 15
                },
                {
                    name: 'Lane Departure Warning',
                    description: 'Lane Departure Warning',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.ldw',
                    // width: 15
                },
                {
                    name: 'Lane Keep Assist',
                    description: 'Lane Keep Assist',
                    type: 'boolean',
                    path: 'specs.controls.safetyFeatures.lka',
                    // width: 15
                },
                {
                    name: 'Optional Roadwatch Features',
                    description: 'Checking box will publish disclaimer on spec sheet: "Some Roadwatch features may be optional. See your sales associate for more information."',
                    type: 'boolean',
                    path: 'specs.controls.roadwatchFeatures.optional',
                    // width: 15
                },
            ]
        },
        {
            name: 'Hitch',
            type: 'category',
            fields: [
                {
                    name: 'Capacity',
                    columnName: 'Hitch Capacity',
                    type: 'number',
                    width: 30,
                    path: 'specs.hitch.capacity',
                    suffix: {
                        text: 'lbs',
                        width: 20
                    }
                }
            ]
        },
        {
            name: 'Warranty',
            type: 'category',
            fields: [
                {
                    name: 'Engine',
                    type: 'text',
                    width: 80,
                    path: 'specs.warranty.engine',
                }
            ]
        }
    ]
});
